@use "../util" as *;

.footer {
  display: flex;
  background-color: var(--ei);
  color: var(--alpha);
  font-family: var(--primary-font);

  @include breakpoint(medium) {
    height: 100%;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    padding: 5rem 10rem 5rem 10rem;

    @include breakpoint(small) {
      padding: 2rem;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
  &__logo {
    @include breakpoint(small) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &__logo img {
    width: 125px;
    display: flex;

    @include breakpoint(small) {
      margin-bottom: 2.5rem;
    }
  }

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    @include breakpoint(small) {
      margin-bottom: 2.5rem;
    }
  }

  &__item:last-child {
    margin: 0;
  }

  &__item-column {
    line-height: 1.3;
  }

  &__links {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: 700;

    @include breakpoint(small) {
      margin-top: 2.5rem;
    }
  }

  &__link {
    margin-bottom: 1.5rem;
    transition: all 0.5s ease;
  }

  &__link:last-child {
    margin-bottom: 0;
  }

  &__link:hover {
    scale: 1.1;
  }

  &__socials {
    display: flex;
    align-items: center;

    @include breakpoint(small) {
      width: 100%;
      justify-content: center;
    }
  }

  &__social {
    margin-right: 1.5rem;
  }

  &__social svg path {
    transition: all 0.5s ease;
  }

  &__social:last-child {
    margin-right: 0;
  }

  &__social:hover svg path {
    fill: var(--beta);
  }

  &__social-border {
    border: 1px solid var(--alpha);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem;
    border-radius: 100%;
    transition: all 0.5s ease;
  }

  &__social-border:hover {
    border: 1px solid var(--beta);
  }
}

.footer__copyright {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--ei);
  color: var(--alpha);
  font-size: 1.4rem;
  font-family: var(--primary-font);
  padding-bottom: 1rem;
}
