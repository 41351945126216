@use "../util" as *;

:root {
  --primary-font: "Comfortaa", cursive;
  --secondary-font: "Roboto", sans-serif;
  --font-xl: 2.4rem;
  --font-l: 1.8rem;
  --font-m: 1.6rem;
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

h1 {
  font-size: 6.4rem; // 64 px

  @include breakpoint(small) {
    font-size: 4.8rem;
  }
}

h2 {
  font-size: 4.8rem; // 48 px
  line-height: 1.6;

  @include breakpoint(small) {
    font-size: 3.2rem;
  }
}

h3 {
  font-size: 3.4rem; // 34 px
  line-height: 1.6;
  margin-bottom: 0;

  @include breakpoint(small) {
    font-size: 2.6rem;
  }
}

a,
a:visited,
a:active {
  text-decoration: none;
  color: inherit;
}
