@use "../util" as *;

.navbar {
  position: fixed;
  width: 100%;
  color: var(--alpha);
  z-index: 9998;

  &__container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 10rem 0 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    height: 8.4rem;

    @include breakpoint(medium) {
      padding: 2rem;
    }
  }

  &__logo img {
    width: 125px;
    display: flex;
  }

  &__menu {
    display: flex;

    @include breakpoint(medium) {
      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 50%;
      right: 0px;
      height: 100vh;
      top: 0;
      transform: translateX(100%);
      background-color: var(--alpha);
      transition: all 0.5s ease-in;
      z-index: 9997;
    }
  }

  &__menu ul {
    display: flex;

    @include breakpoint(medium) {
      flex-direction: column;
    }
  }
  &__link {
    font-family: var(--primary-font);
    list-style: none;
    margin-right: 2rem;
    transition: all 0.5s ease;

    @include breakpoint(medium) {
      margin: 0 0 3rem 0;
    }
  }
  &__link:last-child {
    margin-right: 0;
  }

  &__link:hover {
    color: var(--beta);
    scale: 1.1;
  }

  &__burger {
    display: none;
    cursor: pointer;
    z-index: 9999;

    @include breakpoint(medium) {
      display: block;
    }
  }

  &__burger div {
    background-color: var(--alpha);
    height: 3px;
    width: 25px;
    margin: 5px;
    transition: all 0.5s ease;
  }

  &__socials {
    display: flex;
    @include breakpoint(medium) {
      display: none;
    }
  }
  &__social {
    display: inline-block;
    overflow: hidden;
    line-height: 2rem;
    height: 2.4rem;
    margin-right: 1.8rem;
  }

  &__social:last-child {
    margin-right: 0;
  }

  &__youtube {
    margin-top: 0.5px;
    height: 2.2rem !important;
  }

  &__youtube {
    width: 2.8rem !important;
  }

  &__social img {
    width: 2.4rem;
  }
}

.navbar--active {
  background-color: var(--alpha);
  color: var(--gamma) !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  transition: all 0.5s ease;
}

.navbar--active .navbar__burger div {
  background-color: var(--gamma);
}

.menu--active {
  transform: translateX(0);
  color: black;
}

.toggle .navbar__line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
  background-color: var(--gamma);
}
.toggle .navbar__line2 {
  opacity: 0;
  background-color: var(--gamma);
}
.toggle .navbar__line3 {
  transform: rotate(45deg) translate(-5px, -6px);
  background-color: var(--gamma);
}

// Sticky whatsapp

.whatsapp {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 900;
}
