@use "../util" as *;

.content {
  display: flex;
  height: 100vh;
  background: url("../../../assets/content-bg.jpg");
  background-size: cover;

  @include breakpoint(medium) {
    height: 100%;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 10rem 0 10rem;
    width: 100%;

    @include breakpoint(medium) {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;
    font-family: var(--primary-font);
    color: var(--gamma);

    @include breakpoint(medium) {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  &__left h2 {
    margin-bottom: 7.5rem;

    @include breakpoint(medium) {
      margin-bottom: 4rem;
      margin-top: 2rem;
    }
  }
  &__left ul {
    padding: 0;
    margin: 0;
  }
  &__left li {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 2.4rem;
    margin-bottom: 3.5rem;

    @include breakpoint(medium) {
      font-size: 2rem;
      text-align: center;
      justify-content: center;
    }
  }

  &__left li:last-child {
    margin-bottom: 0;
  }
  &__left li img {
    width: 25px;
    margin-right: 15px;

    @include breakpoint(medium) {
      display: none;
    }
  }
  &__button {
    color: var(--alpha);
    padding: 2rem 8rem;
    margin-top: 7.5rem;
    border-radius: 1rem;
    border: solid 2px transparent;
    background-color: var(--beta);
    font-family: var(--primary-font);
    font-weight: 700;
    font-size: 2rem;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    transition: all 0.3s ease-out;

    @include breakpoint(medium) {
      font-size: 1.8rem;
      margin-top: 4rem;
      margin-bottom: 4rem;
      padding: 1.5rem 3rem;
    }

    @include breakpoint(medium) {
      margin-bottom: 2rem;
    }
  }

  &__button:hover {
    background-color: transparent;
    border: solid 2px;
    color: var(--beta);
  }

  &__right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 60%;

    @include breakpoint(medium) {
      width: 100%;
      justify-content: center;
    }
  }

  &__slider {
    border-radius: 30px;
    overflow: hidden;
    max-width: 800px;
    position: relative;
    box-shadow: 0 0 30px -20px #223344;
    margin: 3rem;
    z-index: 0;
  }

  &__slide {
    display: none;
  }
  &__img {
    display: block;
    width: 100%;
  }

  &__prev {
    left: 0%;

    margin-left: 3rem;
  }

  &__next {
    right: 0%;
    margin-right: 3rem;
  }

  .content__prev,
  .content__next {
    position: absolute;
    top: 50%;
    z-index: 2;
    transform: translate(0, -50%);
    width: auto;
    padding: 20px;
    color: white;
    font-weight: bold;
    font-size: 24px;
    border-radius: 0 8px 8px 0;
    background: rgba(173, 216, 230, 0.1);
    user-select: none;
    cursor: pointer;
  }

  .content__prev:hover,
  .content__next:hover {
    background-color: rgba(173, 216, 230, 0.3);
  }
  &__dots {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;
    bottom: 10px;
  }
  &__dot {
    cursor: pointer;
    height: 14px;
    width: 14px;
    margin: 0 4px;
    background-color: rgba(173, 216, 230, 0.2);
    backdrop-filter: blur(2px);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
  }

  .dots-active,
  .content__dot:hover {
    background-color: rgba(173, 216, 230, 0.8);
  }

  /* transition animation */
  .animate {
    -webkit-animation-name: animate;
    -webkit-animation-duration: 1s;
    animation-name: animate;
    animation-duration: 2s;
  }

  @keyframes animate {
    from {
      transform: scale(1.1) rotateY(10deg);
    }
    to {
      transform: scale(1) rotateY(0deg);
    }
  }

  // &__right div {
  //   width: 700px;
  //   height: 400px;
  //   border: solid 2px;

  //   @include breakpoint(small) {
  //     width: 100%;
  //     height: 200px;
  //     margin: 3rem;
  //   }
  // }
}
