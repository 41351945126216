@use "../util/" as *;

html {
  font-size: 62.5%; // 1 rem = 10 px
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.6;
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
  @include breakpoint(medium) {
    overflow-x: hidden;
  }
}
