@use "../util" as *;

.services {
  display: flex;
  background: url("../../../assets/content-bg.jpg");
  background-size: cover;

  @include breakpoint(medium) {
    height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    padding: 0 10rem 0 10rem;

    @include breakpoint(small) {
      padding: 2rem;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: var(--primary-font);
    width: 100%;
  }

  &__header h2 {
    margin-bottom: 1rem;
    margin-top: 10rem;
  }

  &__header-line {
    height: 1.5px;
    background: #cad0d7;
    transition: all 0.5s ease;
  }

  &__cards {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include breakpoint(medium) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 25rem;
    max-width: 25rem;
    margin-top: 7rem;
    padding: 2rem;
    background-color: var(--alpha);
    font-family: var(--primary-font);
    color: var(--gamma);
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    cursor: context-menu;
  }

  &__card-img {
    width: 7.5rem;
    margin: 0;
  }

  &__card-header {
    margin: 1.5rem;
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--beta);
  }

  &__card-text {
    margin: 0;
    text-align: center;
    line-height: 1.3;
    font-size: 1.6rem;
  }
  &__content {
    display: flex;
    max-width: 1000px;
    flex-direction: column;
    align-items: center;
  }

  &__content-header {
    margin: 10rem 0 10rem 0;
    font-family: var(--primary-font);
    color: var(--gamma);

    @include breakpoint(small) {
      margin: 4rem 0 4rem 0;
      text-align: center;
    }
  }

  &__accordion-item {
    cursor: pointer;
  }
  &__accordion-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    cursor: pointer;
  }
  &__accordion-item-header h4 {
    margin: 0;
    margin-left: 2rem;
    font-family: var(--secondary-font);
    color: var(--gamma);
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1;
    transition: all 0.5s ease;

    @include breakpoint(small) {
      font-size: 2rem;
      width: 70%;
    }
  }

  &__accordion-item-header svg {
    width: 32px;
    height: 32px;
  }

  &__accordion-item-body {
    max-height: 0;
    margin: 2rem 0 2rem 2rem;
    color: var(--gamma);
    overflow: hidden;
    transition: max-height 1.4s ease;

    @include breakpoint(small) {
      font-size: 1.6rem;
    }
  }
}

svg {
  transition: transform 0.5s ease-in;
}

// Active States
.services__accordion-item.accordion--active .services__accordion-item-body {
  max-height: 700px;

  @include breakpoint(small) {
    max-height: 1300px;
  }
}

.services__accordion-item.accordion--active svg {
  transform: rotate(180deg);
}

.services__accordion-item.accordion--active svg path {
  fill: var(--beta);
}

.services__accordion-item.accordion--active h4 {
  color: var(--beta);
}

.services__accordion-item.accordion--active .services__header-line {
  background: var(--beta);
}

// Accordion Hover

.services__accordion-item:hover h4 {
  color: var(--beta);
}

.services__accordion-item:hover .services__header-line {
  background: var(--beta);
}

.services__accordion-item:hover svg path {
  fill: var(--beta);
}
