html {
  font-size: 62.5%;
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.6;
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
}
@media (max-width: 74.9375em) {
  body {
    overflow-x: hidden;
  }
}

:root {
  --alpha: #ffffff;
  --beta: #e88604;
  --gamma: #3a3b3c;
  --delta: #a9a9a9;
  --ei: #1e1e1e;
}

:root {
  --primary-font: "Comfortaa", cursive;
  --secondary-font: "Roboto", sans-serif;
  --font-xl: 2.4rem;
  --font-l: 1.8rem;
  --font-m: 1.6rem;
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

h1 {
  font-size: 6.4rem;
}
@media (max-width: 39.9375em) {
  h1 {
    font-size: 4.8rem;
  }
}

h2 {
  font-size: 4.8rem;
  line-height: 1.6;
}
@media (max-width: 39.9375em) {
  h2 {
    font-size: 3.2rem;
  }
}

h3 {
  font-size: 3.4rem;
  line-height: 1.6;
  margin-bottom: 0;
}
@media (max-width: 39.9375em) {
  h3 {
    font-size: 2.6rem;
  }
}

a,
a:visited,
a:active {
  text-decoration: none;
  color: inherit;
}

.navbar {
  position: fixed;
  width: 100%;
  color: var(--alpha);
  z-index: 9998;
}
.navbar__container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10rem 0 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  height: 8.4rem;
}
@media (max-width: 74.9375em) {
  .navbar__container {
    padding: 2rem;
  }
}
.navbar__logo img {
  width: 125px;
  display: flex;
}
.navbar__menu {
  display: flex;
}
@media (max-width: 74.9375em) {
  .navbar__menu {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 50%;
    right: 0px;
    height: 100vh;
    top: 0;
    transform: translateX(100%);
    background-color: var(--alpha);
    transition: all 0.5s ease-in;
    z-index: 9997;
  }
}
.navbar__menu ul {
  display: flex;
}
@media (max-width: 74.9375em) {
  .navbar__menu ul {
    flex-direction: column;
  }
}
.navbar__link {
  font-family: var(--primary-font);
  list-style: none;
  margin-right: 2rem;
  transition: all 0.5s ease;
}
@media (max-width: 74.9375em) {
  .navbar__link {
    margin: 0 0 3rem 0;
  }
}
.navbar__link:last-child {
  margin-right: 0;
}
.navbar__link:hover {
  color: var(--beta);
  scale: 1.1;
}
.navbar__burger {
  display: none;
  cursor: pointer;
  z-index: 9999;
}
@media (max-width: 74.9375em) {
  .navbar__burger {
    display: block;
  }
}
.navbar__burger div {
  background-color: var(--alpha);
  height: 3px;
  width: 25px;
  margin: 5px;
  transition: all 0.5s ease;
}
.navbar__socials {
  display: flex;
}
@media (max-width: 74.9375em) {
  .navbar__socials {
    display: none;
  }
}
.navbar__social {
  display: inline-block;
  overflow: hidden;
  line-height: 2rem;
  height: 2.4rem;
  margin-right: 1.8rem;
}
.navbar__social:last-child {
  margin-right: 0;
}
.navbar__youtube {
  margin-top: 0.5px;
  height: 2.2rem !important;
}
.navbar__youtube {
  width: 2.8rem !important;
}
.navbar__social img {
  width: 2.4rem;
}

.navbar--active {
  background-color: var(--alpha);
  color: var(--gamma) !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  transition: all 0.5s ease;
}

.navbar--active .navbar__burger div {
  background-color: var(--gamma);
}

.menu--active {
  transform: translateX(0);
  color: black;
}

.toggle .navbar__line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
  background-color: var(--gamma);
}

.toggle .navbar__line2 {
  opacity: 0;
  background-color: var(--gamma);
}

.toggle .navbar__line3 {
  transform: rotate(45deg) translate(-5px, -6px);
  background-color: var(--gamma);
}

.whatsapp {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 900;
}

.hero {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../assets/hero-bg.JPG");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--primary-font);
  font-weight: 700;
  color: var(--alpha);
  text-align: center;
}

.content {
  display: flex;
  height: 100vh;
  background: url("../../../assets/content-bg.jpg");
  background-size: cover;
  /* transition animation */
}
@media (max-width: 74.9375em) {
  .content {
    height: 100%;
  }
}
.content__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10rem 0 10rem;
  width: 100%;
}
@media (max-width: 74.9375em) {
  .content__container {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
}
.content__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  font-family: var(--primary-font);
  color: var(--gamma);
}
@media (max-width: 74.9375em) {
  .content__left {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
.content__left h2 {
  margin-bottom: 7.5rem;
}
@media (max-width: 74.9375em) {
  .content__left h2 {
    margin-bottom: 4rem;
    margin-top: 2rem;
  }
}
.content__left ul {
  padding: 0;
  margin: 0;
}
.content__left li {
  display: flex;
  align-items: center;
  list-style: none;
  font-size: 2.4rem;
  margin-bottom: 3.5rem;
}
@media (max-width: 74.9375em) {
  .content__left li {
    font-size: 2rem;
    text-align: center;
    justify-content: center;
  }
}
.content__left li:last-child {
  margin-bottom: 0;
}
.content__left li img {
  width: 25px;
  margin-right: 15px;
}
@media (max-width: 74.9375em) {
  .content__left li img {
    display: none;
  }
}
.content__button {
  color: var(--alpha);
  padding: 2rem 8rem;
  margin-top: 7.5rem;
  border-radius: 1rem;
  border: solid 2px transparent;
  background-color: var(--beta);
  font-family: var(--primary-font);
  font-weight: 700;
  font-size: 2rem;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
@media (max-width: 74.9375em) {
  .content__button {
    font-size: 1.8rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding: 1.5rem 3rem;
  }
}
@media (max-width: 74.9375em) {
  .content__button {
    margin-bottom: 2rem;
  }
}
.content__button:hover {
  background-color: transparent;
  border: solid 2px;
  color: var(--beta);
}
.content__right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 60%;
}
@media (max-width: 74.9375em) {
  .content__right {
    width: 100%;
    justify-content: center;
  }
}
.content__slider {
  border-radius: 30px;
  overflow: hidden;
  max-width: 800px;
  position: relative;
  box-shadow: 0 0 30px -20px #223344;
  margin: 3rem;
  z-index: 0;
}
.content__slide {
  display: none;
}
.content__img {
  display: block;
  width: 100%;
}
.content__prev {
  left: 0%;
  margin-left: 3rem;
}
.content__next {
  right: 0%;
  margin-right: 3rem;
}
.content .content__prev,
.content .content__next {
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translate(0, -50%);
  width: auto;
  padding: 20px;
  color: white;
  font-weight: bold;
  font-size: 24px;
  border-radius: 0 8px 8px 0;
  background: rgba(173, 216, 230, 0.1);
  user-select: none;
  cursor: pointer;
}
.content .content__prev:hover,
.content .content__next:hover {
  background-color: rgba(173, 216, 230, 0.3);
}
.content__dots {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
  bottom: 10px;
}
.content__dot {
  cursor: pointer;
  height: 14px;
  width: 14px;
  margin: 0 4px;
  background-color: rgba(173, 216, 230, 0.2);
  backdrop-filter: blur(2px);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
}
.content .dots-active,
.content .content__dot:hover {
  background-color: rgba(173, 216, 230, 0.8);
}
.content .animate {
  -webkit-animation-name: animate;
  -webkit-animation-duration: 1s;
  animation-name: animate;
  animation-duration: 2s;
}
@keyframes animate {
  from {
    transform: scale(1.1) rotateY(10deg);
  }
  to {
    transform: scale(1) rotateY(0deg);
  }
}

.about {
  display: flex;
  color: var(--alpha);
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../assets/aboutus-bg.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}
@media (max-width: 74.9375em) {
  .about {
    height: 100%;
  }
}
.about__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 0 10rem 0 10rem;
}
@media (max-width: 39.9375em) {
  .about__container {
    padding: 2rem;
    font-size: 1.6rem;
  }
}
.about__header {
  font-family: var(--primary-font);
}
.about__text {
  font-family: var(--secondary-font);
}

.services {
  display: flex;
  background: url("../../../assets/content-bg.jpg");
  background-size: cover;
}
@media (max-width: 74.9375em) {
  .services {
    height: 100%;
  }
}
.services__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 0 10rem 0 10rem;
}
@media (max-width: 39.9375em) {
  .services__container {
    padding: 2rem;
  }
}
.services__header {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: var(--primary-font);
  width: 100%;
}
.services__header h2 {
  margin-bottom: 1rem;
  margin-top: 10rem;
}
.services__header-line {
  height: 1.5px;
  background: #cad0d7;
  transition: all 0.5s ease;
}
.services__cards {
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 74.9375em) {
  .services__cards {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.services__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 25rem;
  max-width: 25rem;
  margin-top: 7rem;
  padding: 2rem;
  background-color: var(--alpha);
  font-family: var(--primary-font);
  color: var(--gamma);
  border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  cursor: context-menu;
}
.services__card-img {
  width: 7.5rem;
  margin: 0;
}
.services__card-header {
  margin: 1.5rem;
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--beta);
}
.services__card-text {
  margin: 0;
  text-align: center;
  line-height: 1.3;
  font-size: 1.6rem;
}
.services__content {
  display: flex;
  max-width: 1000px;
  flex-direction: column;
  align-items: center;
}
.services__content-header {
  margin: 10rem 0 10rem 0;
  font-family: var(--primary-font);
  color: var(--gamma);
}
@media (max-width: 39.9375em) {
  .services__content-header {
    margin: 4rem 0 4rem 0;
    text-align: center;
  }
}
.services__accordion-item {
  cursor: pointer;
}
.services__accordion-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  cursor: pointer;
}
.services__accordion-item-header h4 {
  margin: 0;
  margin-left: 2rem;
  font-family: var(--secondary-font);
  color: var(--gamma);
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 1;
  transition: all 0.5s ease;
}
@media (max-width: 39.9375em) {
  .services__accordion-item-header h4 {
    font-size: 2rem;
    width: 70%;
  }
}
.services__accordion-item-header svg {
  width: 32px;
  height: 32px;
}
.services__accordion-item-body {
  max-height: 0;
  margin: 2rem 0 2rem 2rem;
  color: var(--gamma);
  overflow: hidden;
  transition: max-height 1.4s ease;
}
@media (max-width: 39.9375em) {
  .services__accordion-item-body {
    font-size: 1.6rem;
  }
}

svg {
  transition: transform 0.5s ease-in;
}

.services__accordion-item.accordion--active .services__accordion-item-body {
  max-height: 700px;
}
@media (max-width: 39.9375em) {
  .services__accordion-item.accordion--active .services__accordion-item-body {
    max-height: 1300px;
  }
}

.services__accordion-item.accordion--active svg {
  transform: rotate(180deg);
}

.services__accordion-item.accordion--active svg path {
  fill: var(--beta);
}

.services__accordion-item.accordion--active h4 {
  color: var(--beta);
}

.services__accordion-item.accordion--active .services__header-line {
  background: var(--beta);
}

.services__accordion-item:hover h4 {
  color: var(--beta);
}

.services__accordion-item:hover .services__header-line {
  background: var(--beta);
}

.services__accordion-item:hover svg path {
  fill: var(--beta);
}

.contact {
  display: flex;
  background: url("../../../assets/contact-bg.jpg");
  background-size: cover;
  background-position: 75% -30%;
  height: 100vh;
  height: 100%;
}
@media (max-width: 74.9375em) {
  .contact {
    height: 100%;
    background: var(--alpha);
  }
}
.contact__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 0 10rem 0 10rem;
}
@media (max-width: 39.9375em) {
  .contact__container {
    padding: 2rem;
  }
}
.contact__header {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10rem;
  font-family: var(--primary-font);
  color: var(--gamma);
}
@media (max-width: 39.9375em) {
  .contact__header {
    margin-top: 0;
  }
}
.contact__icon {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
.contact__item {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  font-family: var(--primary-font);
  font-size: 1.6rem;
  color: var(--gamma);
}
.contact__item-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact__item-column div {
  line-height: 1.3;
}
@media (max-width: 39.9375em) {
  .contact__map-container {
    display: flex;
    justify-content: center;
  }
}
.contact__map {
  border-radius: 5px;
  margin-bottom: 4rem;
}
@media (max-width: 39.9375em) {
  .contact__map {
    width: 100%;
    height: 250px;
  }
}

.footer {
  display: flex;
  background-color: var(--ei);
  color: var(--alpha);
  font-family: var(--primary-font);
}
@media (max-width: 74.9375em) {
  .footer {
    height: 100%;
  }
}
.footer__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 5rem 10rem 5rem 10rem;
}
@media (max-width: 39.9375em) {
  .footer__container {
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
@media (max-width: 39.9375em) {
  .footer__logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.footer__logo img {
  width: 125px;
  display: flex;
}
@media (max-width: 39.9375em) {
  .footer__logo img {
    margin-bottom: 2.5rem;
  }
}
.footer__icon {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
.footer__item {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
@media (max-width: 39.9375em) {
  .footer__item {
    margin-bottom: 2.5rem;
  }
}
.footer__item:last-child {
  margin: 0;
}
.footer__item-column {
  line-height: 1.3;
}
.footer__links {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 700;
}
@media (max-width: 39.9375em) {
  .footer__links {
    margin-top: 2.5rem;
  }
}
.footer__link {
  margin-bottom: 1.5rem;
  transition: all 0.5s ease;
}
.footer__link:last-child {
  margin-bottom: 0;
}
.footer__link:hover {
  scale: 1.1;
}
.footer__socials {
  display: flex;
  align-items: center;
}
@media (max-width: 39.9375em) {
  .footer__socials {
    width: 100%;
    justify-content: center;
  }
}
.footer__social {
  margin-right: 1.5rem;
}
.footer__social svg path {
  transition: all 0.5s ease;
}
.footer__social:last-child {
  margin-right: 0;
}
.footer__social:hover svg path {
  fill: var(--beta);
}
.footer__social-border {
  border: 1px solid var(--alpha);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  border-radius: 100%;
  transition: all 0.5s ease;
}
.footer__social-border:hover {
  border: 1px solid var(--beta);
}

.footer__copyright {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--ei);
  color: var(--alpha);
  font-size: 1.4rem;
  font-family: var(--primary-font);
  padding-bottom: 1rem;
}