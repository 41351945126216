@use "../util" as *;

.hero {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../assets/hero-bg.JPG");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--primary-font);
    font-weight: 700;
    color: var(--alpha);
    text-align: center;
  }
}
