@use "../util" as *;

.about {
  display: flex;
  color: var(--alpha);
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../assets/aboutus-bg.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  @include breakpoint(medium) {
    height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    padding: 0 10rem 0 10rem;

    @include breakpoint(small) {
      padding: 2rem;
      font-size: 1.6rem;
    }
  }

  &__header {
    font-family: var(--primary-font);
  }

  &__text {
    font-family: var(--secondary-font);
  }
}
