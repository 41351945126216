@use "../util" as *;

.contact {
  display: flex;
  background: url("../../../assets/contact-bg.jpg");
  background-size: cover;
  background-position: 75% -30%;
  height: 100vh;
  height: 100%;

  @include breakpoint(medium) {
    height: 100%;
    background: var(--alpha);
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    padding: 0 10rem 0 10rem;

    @include breakpoint(small) {
      padding: 2rem;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10rem;
    font-family: var(--primary-font);
    color: var(--gamma);

    @include breakpoint(small) {
      margin-top: 0;
    }
  }
  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    font-family: var(--primary-font);
    font-size: 1.6rem;
    color: var(--gamma);
  }

  &__item-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item-column div {
    line-height: 1.3;
  }

  &__map-container {
    @include breakpoint(small) {
      display: flex;
      justify-content: center;
    }
  }
  &__map {
    border-radius: 5px;
    margin-bottom: 4rem;

    @include breakpoint(small) {
      width: 100%;
      height: 250px;
    }
  }
}
